import common from './common.json';
import orders from './orders.json';
import userRequests from './userRequests.json';
import transactions from './transactions.json';
import paymentOrders from './paymentOrders.json';
import bankAccounts from './bank-accounts.json';
import designers from './designers.json';
import customers from './customers.json';

export default {
  ...common,
  ...orders,
  ...userRequests,
  ...transactions,
  ...paymentOrders,
  ...bankAccounts,
  ...designers,
  ...customers,
};
