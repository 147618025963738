import NotFoundIcon from 'assets/icons/404-error.svg';
import styled from 'styled-components';

const NotFoundPageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  min-height: 500px;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background-color: ${({ theme }) => theme.palette.primary};

  .main {
    padding: 20px;
  }

  img {
    width: 100%;
  }

  .text-div {
    margin: 20px 0;

    > div {
      line-height: 1;
      font-size: 30px;
    }

    > div:first-child {
      font-size: 80px;
    }
  }
`;

function NotFoundPage() {
  return (
    <NotFoundPageWrapper>
      <div className="main">
        <img src={NotFoundIcon} alt="Not Found" />
        <div className="text-div">
          <div>404</div>
          <div>Page not found</div>
        </div>
      </div>
    </NotFoundPageWrapper>
  );
}

export default NotFoundPage;
