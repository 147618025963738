import { Avatar, Dropdown, Menu } from 'antd';
import { useAuth } from 'hooks/useAuth';
import styled from 'styled-components';

const RightContentHeader = () => {
  const { user, logout } = useAuth();
  const menu = (
    <Menu>
      <Menu.Item onClick={logout} key="logout">
        Logout
      </Menu.Item>
    </Menu>
  );
  
  return (
    <DropdownWrapper overlay={menu}>
      <div className="info-wrapper">
        <div className="info">
          <span className="name">
            {user?.full_name}
          </span>
          <span>
            {user?.role}
          </span>
        </div>
        <Avatar size="large" src={user?.avatar}>
          {user?.full_name?.slice(0, 1)}
        </Avatar>
      </div>
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled(Dropdown)`
  &.info-wrapper {
    display: flex;
    align-items: center;
  }

  .info {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 10px;

    span {
      line-height: normal;
    }

    .name {
      font-weight: 600;
    }
  }
`;

export default RightContentHeader;
