import { Route } from 'react-router-dom';
import loadable from 'utils/loadable';
// import Register from '../../pages/Register';

export const PUBLIC_ROUTES = [
  {
    path: '/login',
    component: loadable(import('pages/Login')),
    exact: true,
  },
  {
    path: '/forgot-password',
    component: loadable(import('pages/ForgotPassword')),
    exact: true,
  },
  {
    path: '/reset-password',
    component: loadable(import('pages/ResetPassword')),
    exact: true,
  },
];

const publicRoutes = () =>
  PUBLIC_ROUTES.map((route) => <Route {...route} key={route.path} />);

export default publicRoutes;
