import { useHasRole, useAuth } from 'hooks/useAuth';
import PrivateLayoutPage from 'layouts/PrivateLayout';
import { flatMap, groupBy, map } from 'lodash';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadable from 'utils/loadable';

const routes = [
  {
    path: '/designers',
    routes: [
      {
        path: '/:id',
        component: loadable(import('pages/designers/Detail')),
      },
    ],
  },
  {
    path: '/customers',
    routes: [
      {
        path: '/:id',
        component: loadable(import('pages/customers/Detail')),
      },
    ],
  },
  {
    path: '/userRequests',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/userRequests/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/userRequests/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/userRequests/Edit')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/userRequests/Show')),
      },
    ],
  },
  {
    path: '/waitingConfirm',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/customers/waitingList')),
      },
    ],
  },
  {
    path: '/confirmed-payment',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/payments/ConfirmedList')),
      },
    ],
  },

  {
    path: '/transactions',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/transactions/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/transactions/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/transactions/Edit')),
      },
      {
        path: '/:id/invoice',
        component: loadable(import('pages/transactions/Invoice')),
        hasPrivateLayoutWrapper: false,
      },
      {
        path: '/:id',
        component: loadable(import('pages/transactions/Show')),
      },
    ],
  },

  {
    path: '/paymentOrders',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/paymentOrders/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/paymentOrders/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/paymentOrders/Edit')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/paymentOrders/Show')),
      },
    ],
  },
  {
    path: '/pendingRewards',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/pendingRewards/Customers')),
        exact: true,
      },
      {
        path: '/outsources',
        component: loadable(import('pages/pendingRewards/Outsources')),
      },
      {
        path: '/customers',
        component: loadable(import('pages/pendingRewards/Customers')),
      },
    ],
  },
  {
    path: '/bank-accounts',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/bankAccounts/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/bankAccounts/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/bankAccounts/Edit')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/bankAccounts/Show')),
      },
    ],
  },
  {
    path: '/',
    component: loadable(import('pages/Home')),
    exact: true,
    title: 'dashboard.title',
  },
  {
    path: '/payments',
    exact: true,
    title: 'payments.header',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/payments/Customers')),
        exact: true,
      },
      {
        path: '/outsources',
        component: loadable(import('pages/payments/Outsources')),
      },
      {
        path: '/customers',
        component: loadable(import('pages/payments/Customers')),
      },
    ],
  },
];

const groupRoutes = groupBy(
  flatMap(routes, (route) => {
    if (route.routes) {
      return map(route.routes, (subRoute) => ({
        ...subRoute,
        path: route.path + subRoute.path,
        exact: subRoute.path === '/',
        component: subRoute.component || route.component,
      }));
    }
    return route;
  }),
  'hasPrivateLayoutWrapper',
);

const wrappedRoutes = map(groupRoutes.undefined, (route) => (
  <PrivateRoute {...route} key={route.path} />
));

function PrivateRoute({ title, roles, component: Component, ...rest }) {
  const hasRole = useHasRole(roles);
  const { isAuthenticated } = useAuth();

  return hasRole ? (
    <Route
      {...rest}
      render={
        (props) =>
          isAuthenticated ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                // eslint-disable-next-line
                state: { from: props.location },
              }}
            />
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  ) : (
    <Route render={null} />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  title: PropTypes.string,
  hasPrivateLayoutWrapper: PropTypes.bool,
  roles: PropTypes.array,
};

const PrivateRoutes = () => (
  <PrivateLayoutPage>
    <Switch>{wrappedRoutes}</Switch>
  </PrivateLayoutPage>
);

export const privateRouteWithoutLayout = () =>
  groupRoutes.false?.map((e) => <Route {...e} key={e.path} />);

export default PrivateRoutes;
