import {
  DashboardFilled,
  DollarOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import ProLayout from '@ant-design/pro-layout';
import { Alert } from 'antd';
import { getConfigApi } from 'api/configs';
import { appConfig } from 'configs/config';
import { useStore } from 'hooks/useStore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import MenuHeader from './MenuHeader';
import MenuSidebarItem from './MenuSidebarItem';
import RightContentHeader from './RightContentHeader';

const PrivateLayout = ({ children }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const ROUTES = {
    routes: [
      {
        path: '/',
        name: 'Dashboard',
        icon: <DashboardFilled />,
        key: '',
      },
      {
        path: '/pendingRewards',
        name: t('pendingRewards.header'),
        icon: <FileDoneOutlined />,
        key: 'pendingRewards',
        parentKeys: ['pendingRewards'],
      },
      {
        path: '/waitingConfirm',
        name: t('waitingConfirms.header'),
        icon: <FileDoneOutlined />,
        key: 'waitingConfirm',
        parentKeys: ['waitingConfirm'],
      },
      {
        path: '/confirmed-payment',
        name: t('payments.confirmed'),
        icon: <FileDoneOutlined />,
        key: 'confirmedConfirm',
      },
      {
        path: '/payments',
        name: t('payments.title'),
        icon: <DollarOutlined />,
        key: 'payments',
      },
      {
        path: '/transactions',
        name: t('transactions.header'),
        icon: <HistoryOutlined />,
        key: 'transactions',
      },
      {
        path: '/bank-accounts',
        name: t('bank-accounts.header'),
        icon: <IdcardOutlined />,
        key: 'bank-accounts',
      },
    ],
  };

  const [settings] = useState({
    colorWeak: false,
    title: appConfig.title,
    headerHeight: 60,
    fixedHeader: true,
    fixSiderbar: true,
    language: 'en-US',
  });
  const [, dispatch] = useStore();
  useQuery(
    'config',
    async () => {
      const configs = await getConfigApi();
      dispatch({ type: 'setConfigs', configs });
    },
    [],
  );

  useEffect(() => {}, []);

  return (
    <div className="h-screen">
      <ProLayout
        route={ROUTES}
        logo={appConfig.logo}
        location={{
          pathname,
        }}
        menuHeaderRender={MenuHeader}
        menuItemRender={MenuSidebarItem}
        rightContentRender={() => <RightContentHeader />}
        {...settings}
        language="en-US"
      >
        <Alert.ErrorBoundary>{children}</Alert.ErrorBoundary>
      </ProLayout>
      {/* <SettingDrawer
        pathname={pathname}
        settings={settings}
        onSettingChange={setSetting}
        disableUrlParams
        hideHintAlert
        hideCopyButton
        language="en-US"
      /> */}
    </div>
  );
};

PrivateLayout.propTypes = {
  logout: PropTypes.func,
  userInfo: PropTypes.object,
};

export default PrivateLayout;
